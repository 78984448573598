export default {
    methods: {
        commaFormat(x) {
            if (x != null) {
                var parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                // if (x % 1 == 0) return `${parts}.00`
                // else
                return parts.join(".");
            }
        },
        currencyFormat(x) {
            if(x != null && x != undefined && x != ''){
                x = Number(x); // Convert to number if it's string
                return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return '0.00';
            }
        },
        sortVariants(row, log){
            // row.sort((a,b)=> ((a.opt1 < b.opt1) ? -1 : (a.opt1 > b.opt1) ? 1 : 0))
            row.sort((a,b)=>{
                let x = parseInt(a.opt1) ? parseInt(a.opt1.trim()) : a.opt1.trim();
                let y = parseInt(b.opt1) ? parseInt(b.opt1.trim()) : b.opt1.trim();
                if(x < y ) return -1;
                else if (x > y) return 1;
                else 0;
            });
            if(log){
                const inStock = row.filter(r=> r.available_stock != 0);
                return new Set([...inStock, ...row]); 
            }
            else{
                return row;
            }
          },

    }
}