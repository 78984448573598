<template>
  <div
    class="flex w-full bg-white h-screen overflow-y-auto items-start make-items-center"
  >
    <!-- From Column -->
    <div
      class="w-full sm:w-full lg:w-1/2 items-center flex justify-center p-8 md:p-16 h-full select-none"
    >
      <div class="max-w-md mx-auto w-full space-y-12">
        <div
          class="flex flex-col sm:flex-row items-center justify-center md:justify-between"
        >
          <!-- <Icon class="h-6 md:h-9 w-auto" name="medusa-logo" /> -->
          <!-- <img
            src="/images/logos/medusa-halloween.svg"
            class="h-6 md:h-9 w-auto"
          /> -->
          <img :src="logoUrl.url" class="h-7 w-auto" />
          <span
            class=" border-transparent h-4 w-0 border-r sm:h-10 sm:border-brand-ash mx-3"
          ></span>
          <span class="text-base md:text-lg font-semibold text-black"
            >Login to Your Account</span
          >
        </div>

        <form class="w-full space-y-6" @submit.prevent="loggedIn">
          <div>
            <Input
              v-model:modelValue="user.email"
              label="E-mail Address"
              placeholder="What’s your email address?"
              :inputClass="
                user.error.email.message || user.error.message ? 'error' : ''
              "
            />
            <span
              class="text-sm font-light text-red-400 py-2"
              v-if="user.error.email.message"
              >{{ user.error.email.message }}</span
            >
          </div>
          <div>
            <Input
              v-model:modelValue="user.password"
              label="Password"
              placeholder="Enter your password"
              :type="showPassword ? 'text' : 'password'"
              :inputClass="
                user.error.password.message || user.error.message ? 'error' : ''
              "
            >
              <template #extra>
                <span
                  @click="showPassword = !showPassword"
                  class="absolute right-4 top-1/2 -mt-2.5 cursor-pointer text-theme-dark-shade hover:text-theme-dark"
                >
                  <EyeIcon class="w-5 h-5" v-if="showPassword" />
                  <EyeOffIcon class="w-5 h-5" v-else />
                </span>
              </template>
            </Input>
            <span
              class="text-sm font-light text-red-400 py-2"
              v-if="user.error.password.message"
              >{{ user.error.password.message }}</span
            >
          </div>
          <div class="flex items-center justify-between">
            <label
              for="remember"
              class="space-x-2 text-brand-body text-opacity-70 select-none  text-sm"
            >
              <input
                type="checkbox"
                id="remember"
                class="text-brand-primary border-brand-ash rounded border-2 focus:ring-transparent focus:outline-none"
              />
              <span>Remember Me</span>
            </label>

            <router-link
              to="/forgot-password"
              class="text-brand-secondary font-medium text-sm"
              >Forgot Password?</router-link
            >
          </div>
          <div>
            <Button
              icon="right-arrow"
              title="Login Now"
              type="submit"
              :loader="user.loading"
            />
            <span
              class="text-sm font-normal text-red-400 py-3 inline-flex items-center"
              v-if="user.error.message"
              ><ExclamationCircleIcon class="w-4 h-4 mr-1" />{{
                user.error.message
              }}</span
            >
          </div>
          <div>
            <p class="block text-center text-brand-body text-opacity-70">
              Don’t have an account?
              <router-link
                to="/join-us"
                class="text-brand-secondary underline font-semibold"
                >Join Us</router-link
              >
            </p>
          </div>
          <div>
            <p class="block text-center text-brand-body text-opacity-70">
              Go back to
              <router-link
                to="/"
                class="text-brand-secondary underline font-semibold"
                >Home Page</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </div>

    <InfoColumn />
  </div>
</template>

<script>
import Auth from "@/modules/Auth";
import Input from "@/components/Input.vue";
import InfoColumn from "../common/InfoColumn";
import axios from "axios";
import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/vue/outline";
import { ref } from "vue";
import Mymixin from "@/modules/mixins";

export default {
  components: { Input, ExclamationCircleIcon, EyeIcon, EyeOffIcon, InfoColumn },
  mixins: [Mymixin],
  name: "Login",
  data() {
    return {
      logoUrl: "",
    };
  },

  mounted() {
    axios.get(process.env.BASE_URL_API + "/logo/get").then((res) => {
      this.logoUrl = res.data;
    });
  },

  setup() {
    const { user, loggedIn, isLoggedIn } = Auth();

    const showPassword = ref(false);

    return {
      user,
      loggedIn,
      isLoggedIn,
      showPassword,
    };
  },
};
</script>
